<template>
  <v-layout id="sign-up" class="gradientBG" align-center justify-center>
    <img class="logo mr-2" src="@/assets/Youtorial-white.png">

    <v-flex class="xs12 sm10 md4">
      <v-card elevation="10">
          <h2 class="text-center py-3">Sign up for YOUTORIAL</h2>
          <div class="error white--text" v-if="errorMessage">
              {{errorMessage}}
          </div>
          <p class="col-10 text-center" style="margin:0 auto">Make "how-to" videos from Youtube<br>way easier to watch.</p>
        <v-card-text>

          <template v-if="showFields">
            <v-text-field
              label="Email"
              v-model="email"
            ></v-text-field>
            <v-text-field
              label="Password"
              v-model="password"
                type="password"
            ></v-text-field>
          </template>

          <template v-else>
            <auth-button :social="'google'" :logInOut="'Sign up'" @authWith="signUp"></auth-button>
            <!-- <auth-button :social="'facebook'" :logInOut="'Sign up'" @authWith="signUp"></auth-button> -->
            <auth-button :social="'password'" :logInOut="'Sign up'" @authWith="showFields = true"></auth-button>
          </template>


          <v-card-actions class="flex-column align-center pa-0">
            <v-btn v-if="showFields" block large class="primary submit" @click="signUp('password')">Sign Up</v-btn>
            <v-btn v-if="showFields" block large outlined class=" submit" @click="showFields = false">Show Social Signups</v-btn>

          </v-card-actions>
          <p class="mb-2 mt-4">or go back to <router-link to="/login">login</router-link>.</p>

        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>

</template>

<script>
  import {auth} from '@/firebase'
  const authButton = () => import("@/components/shared/auth-button.vue")

  export default {
    name: 'signUp',
    metaInfo: {
      title: 'Youtorial: Sign Up'
    },
    components:{
      authButton
    },
    data: function() {
      return {
        email: '',
        password: '',
        errorMessage: null,
        showFields:false,
        kind:null
      }
    },
    methods: {
      signUp: function(kind) {
        let vThis = this;
        vThis.kind = kind;
        if(kind == 'google' || kind == 'facebook'){
          //send to store action
          this.$store.dispatch('loginSocial', {kind})
          .then((user) => {
            // do something with user object
            process.env.NODE_ENV === "development" ? console.log( 'user', user ) : null;
            vThis.logEvent();
            vThis.$router.push('/profile?newAccount=true')
          })
          .catch((err) => {
            vThis.errorMessage = err.message;
          })
        }
        else{
          auth.createUserWithEmailAndPassword(this.email, this.password).then(
            (user) => {
              process.env.NODE_ENV === "development" ? console.log( 'user', user ) : null;
              vThis.logEvent();
              vThis.$router.push('/profile?newAccount=true')
            },
            (err) => {
              alert('Oops. ' + err.message)
              vThis.errorMessage = err.message;
            }
          );
        }
      },
      logEvent(){
        let vThis = this;
        vThis.$logEvent('Account','Created', vThis.kind);
      }
    },
    mounted(){
      this.$store.dispatch('toggleDrawer', false);
    },
  }
</script>

<style lang="scss">
  #sign-up {
    height: 100%;
    position: relative;
    .logo{
      position: absolute;
      top: 10px;
      left: 10px;
      max-width: 220px;
    }

    input {
      margin: 10px 0;
      width: 20%;
      padding: 15px;
    }
    button.submit{
      margin-top: 10px;
      width: 10%;
      cursor: pointer;
    }
    // span {
    //   display: block;
    //   margin-top: 20px;
    //   font-size: 11px;
    // }
  }
</style>
